.main-container {
    background: linear-gradient(90deg, rgb(10, 2, 32) 0%, rgb(26, 2, 24) 100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #fff;
    border-radius: 2rem;
    padding: 0.4em;
    box-shadow: 0 0 .2rem #fff,
              0 0 .2rem #fff,
              0 0 2rem #bc13fe,
              0 0 0.8rem #bc13fe,
              0 0 2.8rem #bc13fe,
              inset 0 0 1.3rem #2b2b2c; 
}
  
h1, h3 {
    font-family: "Moderustic", sans-serif;
    color: white;
    font-size: 2em;
    color: #fff;
    text-shadow:
      0 0 1px #fff,
      0 0 2px #fff,
      0 0 5px #fff,
      0 0 10px #bc13fe,
      0 0 12px #bc13fe,
      0 0 22px #bc13fe,
      0 0 32px #bc13fe,
      0 0 42px #bc13fe;
}

body {
    margin: 0; /* Убирает стандартные отступы */
    height: 100vh; /* Задает полную высоту окна */
    background-color: black;
}

.window-container {
    padding-top: 8%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 90%;
    justify-content: space-around;
}
  
.window {
    width: 20%;
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1); /* Прозрачный фон */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 .1rem #fff,
              0 0 .1rem #fff,
              0 0 1.2rem #9c13fe,
              0 0 0.5rem #8c13fe,
              0 0 2.5rem #8513fe,
              inset 0 0 1.1rem #2b2b2c; 
}

.btn-more {
  margin-top: 10px;
  padding: 0.6em 2em;
  font-family: "Moderustic", sans-serif;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-more:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff006f,
    #2a0039,
    #c800ff,
    #6a00ff,
    #0095ff,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-btn-more 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-btn-more {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.btn-more:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.text-field {
    color:#fff;
    background-color: #2a0039;
    font-family: "Moderustic", sans-serif;
    padding: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 10px;
    border: solid #dcb8df 1px;
}

.exchange-container {
    align-items: flex-start;
}

.exchange-window {
    width: 70%;
}

.return {
    background-image: url('./arrow.png');
    background-size: cover;
    background-position: center;
    width: 100px; /* Ширина кнопки */
    height: 100px;
    cursor: pointer;
    border-radius: 10px;
    position: sticky;
    top: 20px;
}